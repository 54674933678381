import { HttpClient } from '@angular/common/http';
import { AppConfig } from "app/app-config.module";
import { NGXLogger } from "ngx-logger";
import { map, tap } from "rxjs/operators";
import * as FileSaver from 'file-saver';
import * as i0 from "@angular/core";
import * as i1 from "../../../app-config.module";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-logger";
export class PaidModulesService {
    constructor(config, http, logger) {
        this.config = config;
        this.http = http;
        this.logger = logger;
    }
    getUsersPaidModules(searchPattern, skip, take) {
        const url = `${this.config.apiEndpoint}/api/private/PaidServicesManagement/GetUsersAvailablePaidModules?SearchPattern=${searchPattern}&Sort=DaysAllocated&SortOrder=0&Skip=${skip}&Take=${take}`;
        this.logger.debug("HTTP GET users paid modules.", `Url: ${url}`);
        return this.http.get(url)
            .pipe(tap(response => {
            this.logger.debug("Response users paid modules:", response);
        }), map(response => response));
    }
    addUsersPaidModule(addUserAvailablePaidModuleCommand) {
        const url = `${this.config.apiEndpoint}/api/private/PaidServicesManagement/AddUserAvailablePaidModules`;
        this.logger.debug("HTTP POST add user paid module.", `New user paid module: ${addUserAvailablePaidModuleCommand},`, `url: ${url}`);
        return this.http.post(url, addUserAvailablePaidModuleCommand)
            .pipe(tap(response => {
            this.logger.debug("Response add user paid modules:", response);
        }), map(response => response));
    }
    editUsersPaidModule(editUserAvailablePaidModuleCommand) {
        const url = `${this.config.apiEndpoint}/api/private/PaidServicesManagement/EditUserAvailablePaidModules`;
        this.logger.debug("HTTP POST edit user paid module.", `Edit user paid module: ${editUserAvailablePaidModuleCommand},`, `url: ${url}`);
        return this.http.post(url, editUserAvailablePaidModuleCommand)
            .pipe(tap(response => {
            this.logger.debug("Response edit user paid modules:", response);
        }), map(response => response));
    }
    removeUsersPaidModule(removeUserAvailablePaidModuleCommand) {
        const url = `${this.config.apiEndpoint}/api/private/PaidServicesManagement/RemoveUserAvailablePaidModules`;
        this.logger.debug("HTTP POST remove user paid module.", `Remove user paid module: ${removeUserAvailablePaidModuleCommand},`, `url: ${url}`);
        return this.http.post(url, removeUserAvailablePaidModuleCommand)
            .pipe(tap(response => {
            this.logger.debug("Response remove user paid modules:", response);
        }), map(response => response));
    }
    generateLicense(generateModuleLicenseCommand) {
        const url = `${this.config.apiEndpoint}/api/private/PaidServicesManagement/GenerateModuleLicense`;
        this.logger.debug("HTTP POST generate user license.", `Generate license: ${generateModuleLicenseCommand},`, `url: ${url}`);
        return this.http.post(url, generateModuleLicenseCommand)
            .pipe(tap(response => {
            this.logger.debug("Generate license:", response);
        }), tap(response => {
            var blob = new Blob([response.token], { type: "text/plain;charset=utf-8" });
            FileSaver.saveAs(blob, "bonabyte-planner.license");
        }));
    }
}
PaidModulesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaidModulesService_Factory() { return new PaidModulesService(i0.ɵɵinject(i1.APP_CONFIG), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.NGXLogger)); }, token: PaidModulesService, providedIn: "root" });
